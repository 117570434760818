<template>
  <div class="Maze max-w-80vh mx-auto">
    <div v-if="!isFetching">
      <small>{{ makeTime }}s</small>
    </div>
    <div v-if="isFetching">Loading...</div>
    <div v-else :class="`border border-gray-400 grid`" :style="gridStyles">
      <div
        v-for="(block, i) in blocks"
        :class="{
          N: block.N,
          S: block.S,
          E: block.E,
          W: block.W,
          'bg-gray-400 bg-opacity-90': !block.N && !block.S && !block.E && !block.W,
        }"
        class="relative border border-gray-400"
        style="padding-top: 100%"
      >
        <div
          v-if="block.isVeryStart || block.isVeryEnd"
          class="absolute top-1/2 left-1/2 h-3 w-3 transform -translate-x-1/2 -translate-y-1/2 duration-500"
        >
          <div class="animate-ping absolute inset-0 bg-red-500 rounded-full"></div>
          <div class="absolute inset-0 bg-red-500 rounded-full"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Maze',

  props: {
    cols: {
      type: Number,
      default: 100,
    },
    rows: {
      type: Number,
      default: 100,
    },
    level: {
      type: Number,
      default: 40,
    },
  },

  data() {
    return {
      maze: {},
      isFetching: false,
    };
  },

  computed: {
    gridStyles() {
      return {
        'grid-template-columns': `repeat(${this.cols}, minmax(0, 1fr))`,
        'grid-template-rows': `repeat(${this.rows}, minmax(0, 1fr))`,
      };
    },

    blocks() {
      let blocks = [];
      let [startX, startY] = this.maze?.veryStart || [];
      let [endX, endY] = this.maze?.veryEnd || [];
      for (var y = 0; y < this.rows; y++) {
        for (var x = 0; x < this.cols; x++) {
          let block = this.getBlock(x, y);
          if (x === startX && y === startY) {
            block.isVeryStart = true;
          }
          if (x === endX && y === endY) {
            block.isVeryEnd = true;
          }
          blocks.push(block);
        }
      }
      return blocks;
    },

    makeTime() {
      console.log('%c this.maze -->', 'color:#F80', this.maze);
      return Math.round((this.maze?.makeEnd - this.maze?.makeStart) / 100) / 10;
    },
  },

  created() {
    this.isFetching = true;
    axios
      .get(`https://api.mazemaker.app/maze?rows=${this.rows}&cols=${this.cols}&level=${this.level}`)
      .then(({ data }) => {
        this.isFetching = false;
        this.maze = data || {};
      });
  },

  methods: {
    getBlock(x, y) {
      return (this.maze?.blocks && this.maze.blocks[x] && this.maze.blocks[x][y]) || {};
    },
  },
};
</script>

<!-- <style lang="scss" src="./Maze.scss" scoped></style> -->
